import React, { useState } from 'react';
import AboutHeader from './AboutHeader';
import NavMobile from './AboutNavMobile';
import AboutHero from './AboutHero';
import ChefsDetail from './ChefsDetail';
import ChefVideo from './ChefVideo';
import ChefAwards from './ChefAwards';
import ChefFooter from './ChefFooter';

const AboutPage = () => {
  const [navMobile, setNavMobile] = useState(true);
  return (
    <div>
      <AboutHeader setNavMobile={setNavMobile}/>
      <AboutHero />
       {/* mobile nav */}
       <div className={`${navMobile ? 'right-0' : '-right-full'} fixed top-0 bottom-0 w-[340px] transition-all`}>
        <NavMobile setNavMobile={setNavMobile}/>
      </div>
      <ChefsDetail />
      <ChefVideo />
      <ChefAwards />
      <ChefFooter />
    </div>
  )
}

export default AboutPage