import React from 'react';
import a from '../assets/01-1.jpg';
import aa from '../assets/02.jpg';
import aaa from '../assets/03.jpg';
import aaaa from '../assets/04.jpg';
import aaaaa from '../assets/05.jpg';
import aaaaaa from '../assets/06.jpg';


const ChefsDetail = () => {
  return (
    <div className='bg-[#0c0c0c] py-[4rem] lg:px-[6rem] text-white'>
        <div className='container mx-auto'>
            <div>
                {/* text */}
                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-20 px-4 md:px-0'>
                    <a href="luo">
                      <img src={a} alt="" className='border-2 border-[#dcca87] hover:scale-125 duration-300'/>
                      <div className='mt-6'>
                        <h3 className='text-[48px] font-fan'>Kevin Luo</h3>
                      <span className='text-[18px] font-cc text-[#aaaaaa]'>Head Chef</span>
                      </div>
                    </a>
                    <a href="choi">
                      <img src={aa} alt="" className='border-2 border-[#dcca87] hover:scale-125 duration-300'/>
                      <div className='mt-6'>
                        <h3 className='text-[48px] font-fan'>Patrick Choi</h3>
                      <span className='text-[18px] font-cc text-[#aaaaaa]'>Deputy Chef</span> 
                      </div>
                    </a>
                    <a href="stacy">
                       <img src={aaa} alt="" className='border-2 border-[#dcca87] hover:scale-125 duration-300'/>
                       <div className='mt-6'>
                        <h3 className='text-[48px] font-fan'>Stacy Lee</h3>
                      <span className='text-[18px] font-cc text-[#aaaaaa]'>Station Chef</span>
                       </div>
                    </a>
                    <a href="jack">
                        <img src={aaaa} alt="" className='border-2 border-[#dcca87] hover:scale-125 duration-300'/>
                        <div className='mt-6'>
                            <h3 className='text-[48px] font-fan'>Jack Biscoff</h3>
                      <span className='text-[18px] font-cc text-[#aaaaaa]'>Station Chef</span>
                        </div>
                    </a>
                    <a href="aren">
                      <img src={aaaaa} alt="" className='border-2 border-[#dcca87] hover:scale-125 duration-300'/>
                      <div className='mt-6'>
                        <h3 className='text-[48px] font-fan'>Aren Goodman</h3>
                      <span className='text-[18px] font-cc text-[#aaaaaa]'>Junior Chef</span>  
                      </div>
                    </a>
                    <a href="javier">
                       <img src={aaaaaa} alt="" className='border-2 border-[#dcca87] hover:scale-125 duration-300'/>
                       <div className='mt-6'>
                        <h3 className='text-[48px] font-fan'>Javier Dowsing</h3>
                      <span className='text-[18px] font-cc text-[#aaaaaa]'>Junior Chef</span>
                       </div>
                    </a>
                </div>
                {/* text */}
                <div></div>
            </div>
        </div>
    </div>
  )
}

export default ChefsDetail