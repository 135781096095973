import React from 'react';
import chef from './assets/chef.png';
import x from './assets/spoon.svg';
import sign from './assets/sign.png';
import a from './assets/06.jpg';
import { MdHorizontalRule } from 'react-icons/md';



const Chef = () => {
  return (
    <div className='relative bg-[#0c0c0c] -z-10 flex justify-center items-center lg:py-[8rem] xl:px-[6rem] px-4 text-white py-20'>
        <div className='container mx-auto'>
            <div className='lg:flex'>
                {/* img */}
                <div className='flex justify-center items-center w-[80%]'>
                    <img src={a} alt="" className='md:w-full w-[90%] border border-[#dcca87]'/>
                </div>
                {/* text */}
                <div className='flex-col justify-center items-center xl:max-w-[750px] lg:max-w-[450px] lg:ml-12 xl:ml-0 xl:pl-32 md:pt-20 py-12 gap-y-4 px-6 md:px-0'>
                    <h5 className='md:text-[48px] text-[23px] font-fan'>Chef's Word</h5>
                    <div>
                        <img src={x} alt="" className='xl:w-[70px] w-[45px]'/>
                    </div>
                    <h2 className='md:text-[72px] md:leading-[72px] text-[32px] font-fan text-[#dcca87] pb-12'>Javier Dowsing</h2>
                    <p className='font-cc text-[#aaaaaa]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit auctor sit. Auctor sit iaculis in arcu. Vulputate nulla lobortis mauris eget sit. Nulla scelerisque scelerisque consectetur adipiscing elit.</p>
                    <div className='mt-8'>
                    <p className='font-cc flex gap-x-2 text-[#aaaaaa]'><MdHorizontalRule className='text-[#dcca87]'/>Graduated from Culinary Institute of New Jersey, USA.</p>
                    <p className='font-cc flex gap-x-2 text-[#aaaaaa] my-4'><MdHorizontalRule className='text-[#dcca87]'/>Worked as a junior chef at ruso’s Italiano.</p>
                    <p className='font-cc flex gap-x-2 text-[#aaaaaa] mb-4'><MdHorizontalRule className='text-[#dcca87]'/>Contributed 20+ new recipes on International platform.</p>
                    <p className='font-cc flex gap-x-2 text-[#aaaaaa]'><MdHorizontalRule className='text-[#dcca87]'/>Trained under renowned Chef Gusteau Marcellini</p>
                    
                    </div>
                    <div className='pt-20'>
                      <h4 className='text-[32px] font-fan text-[#dcca87]'>Javier Dowsing</h4>
                      <p className='font-cc'>Chef & Founder</p>  
                    </div>
                    <div className='pt-20'>
                        <img src={sign} alt="" className='w-[50%]'/>
                    </div> 
                </div>
            </div>
        </div>
    </div>
  )
}

export default Chef